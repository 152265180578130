import React from "react";

export const LetUsHelpYou = () => (
  <>
    <p>
      Let me know if you have any questions or thoughts in the comments below.
    </p>
    <hr />
    <h2 id="let-us-help-you-on-your-journey-to-quality-faster">
      Let us help you on your journey to Quality&nbsp;Faster
    </h2>
    <p>
      We at Xolvio specialize in helping our clients get more for less. We can
      get you to the holy grail of continuous deployment where every commit can
      go to production&#8202;—&#8202;and yes, even for large enterprises.
    </p>
    <p style={{ marginBottom: 0 }}>
      Feel free to schedule a call or send us a message below to see how we can
      help.
    </p>
  </>
);
