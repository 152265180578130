import React, { useEffect } from "react";
import styled from "styled-components";
import { CenteredContentWrapper, colors, spacing } from "@xolvio/xolvio-ui";
import sizes from "../sizes";

// Helper to add scripts to our page
const insertScript = (src, id, parentElement) => {
  const script = window.document.createElement("script");
  script.async = true;
  script.src = src;
  script.id = id;
  parentElement.appendChild(script);
  return script;
};

// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};

// The actual component
const Comments = ({ id }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return;
    }
    const document = window.document;
    // In case our #commento container exists we can add our commento script
    if (document.getElementById("commento")) {
      insertScript(
        `https://cdn.commento.io/js/commento.js`,
        `commento-script`,
        document.body
      );
    }
    // Cleanup; remove the script from the page
    return () => removeScript(`commento-script`, document.body);
  }, [id]);
  return (
    <CenteredContentWrapper>
      <CommentoWrapper id={`commento`} data-no-fonts data-css-override />
    </CenteredContentWrapper>
  );
};

const CommentoWrapper = styled.div`
  max-width: ${sizes.maxContentWidth}px;
  margin: auto;

  &&& {
    .commento-login-text,
    .commento-markdown-button,
    .commento-timeago,
    .commento-anonymous-checkbox-container input[type="checkbox"] + label,
    .commento-name,
    .commento-markdown-button,
    blockquote,
    a,
    .commento-markdown-help tr td,
    .commento-markdown-help tr td strike,
    .commento-markdown-help tr td b,
    .commento-markdown-help tr td i,
    .commento-subtitle,
    .commento-login-box-container *,
    .commento-root-font * {
      font-family: HK Grotesk !important;
    }

    .commento-submit-button {
      font-size: 15px !important;
      margin-top: ${spacing.mobile.padding.default}px;
      text-transform: none;
      font-family: HK Grotesk;
      font-weight: lighter;
      background-color: ${colors.AcceptanceGreen};
      border-radius: 2px;
      border: none;
      font-size: 18px;
      line-height: 1.56;
      letter-spacing: -0.2px;
      text-align: center;
      color: #ffffff;
      padding: 11px 21px;
      cursor: pointer;
      outline: none;
      margin-right: 0;
    }

    .commento-email-button {
      margin-top: 6px !important;
    }
    .commento-textarea-container textarea,
    .commento-body,
    .commento-body *:not(code) {
      font-family: "Merriweather Light" !important;
    }

    textarea {
      font-weight: normal;
      line-height: 1.8;
      font-size: 18px;
    }

    .commento-logo-container {
      display: none;
    }

    .commento-markdown-help {
      width: 100%;
      margin-top: 40px;

      tr {
        margin-bottom: 10px;

        a,
        blockquote {
          line-height: 1;
          font-size: 16px;
        }
      }

      pre {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .commento-body {
      p,
      p *:not(code) {
        font-size: 16px;
      }

      a {
        color: ${colors.AcceptanceGreen};
      }
    }

    .commento-header {
      padding-right: 12px;
    }

    .commento-avatar {
      background-color: ${colors.AcceptanceGreen} !important;
    }

    .commento-card {
      padding: ${spacing.mobile.padding.default}px 0 6px
        ${spacing.mobile.padding.default}px;
      border-left-color: ${colors.AcceptanceGreen} !important;
    }

    .commento-options {
      margin-right: 0px;
    }
    .commento-sort-policy-buttons-container {
      padding: 24px 12px 24px;

      .commento-sort-policy-button {
        padding: 12px;
        padding-right: 0px;
      }
    }

    .commento-profile-button,
    .commento-sort-policy-button {
      font-family: HK Grotesk !important;

      color: ${colors.AcceptanceGreen} !important;
      background-color: transparent;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      letter-spacing: 0;
      line-height: 1.2;
    }

    pre,
    code {
      background: #edefee;
      font-family: Consolas, Monaco, "Andale Mono", "Lucida Console", monospace;
      text-shadow: 0 1px white;
    }
    code {
      padding: 8px;
      display: block;
      margin-bottom: 5px;
      margin-top: 6px;
    }
    .commento-profile-button {
      margin-top: 12px;
    }
    p {
      padding-right: 12px;
    }
    .commento-name {
      font-size: 16px;
    }
    .commento-avatar {
      margin-top: 5px;
    }
    .commento-anonymous-checkbox-container {
      margin-top: 24px;
      font-family: HK Grotesk;
    }
  }
`;

export default Comments;
