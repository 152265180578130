import React, { useEffect, useRef } from "react"
import PhotoSwipeLightbox from "../../../../static/photoswipe/photoswipe-lightbox.esm.min"
import PhotoSwipe from "../../../../static/photoswipe/photoswipe.esm.min"
import "../../../../static/photoswipe/photoswipe.css"

export const LightBoxHtmlTransformer = ({ html, wrapper }) => {
  const contentRef = useRef(null)

  useEffect(() => {
    if(html && contentRef && contentRef.current) {
      const content = contentRef.current
      content.querySelectorAll('figure').forEach(figure => {
        const imgObjects = figure.getElementsByTagName('img')
        if(imgObjects.length) {
          const img = imgObjects[0]
          const anchor = document.createElement('a')
          anchor.href = img.src
          anchor.classList.add("lightboximage")
          anchor.target = "_blank"
          const originalImage = new Image()
          originalImage.onload = () => {
            anchor.dataset.pswpWidth = originalImage.width.toString()
            anchor.dataset.pswpHeight = originalImage.height.toString()
          }
          originalImage.src = img.src
          anchor.appendChild(img)
          figure.prepend(anchor)
        }
      })
      const lightbox = new PhotoSwipeLightbox({
        gallery: '#photoswipe-container',
        children: 'a.lightboximage',
        pswpModule: PhotoSwipe
      })
      lightbox.init()

      return () => {
        lightbox.destroy()
      }
    }
  }, [html, contentRef])

  return React.createElement(wrapper, {
    dangerouslySetInnerHTML: { __html: html },
    id: 'photoswipe-container',
    ref: contentRef,
  })
};
